import { ResourceKey } from 'i18next'

const translation: ResourceKey = {
  en: {
    translation: {
      hello: "Hello!",
      "username is a required field": "Username is a required field",
      "password is a required field": "Password is a required field",
      "Login": "Login",
      "Senha": "Password",
      "Assinatura Digital": "Digital Signature",
      "Entrar": "Enter",
      "Internal Server Error": "Internal Server Error, try again later.",
      "invalid_grant": "Bad Credentials, username and/or password incorrect, try again.",
      "unauthorized": "Unauthorized",
      "name": "Name",
      "newDocument": "Document",
      "documentType": "Document type",
      "country": "Country",
      "birthday": "Birth date",
      "socialName": "Social name",
      "name is a required field": "Name is a required field",
      "newDocument is a required field": "Document is a required field",
      "documentType is a required field": "Document type is a required field",
      "country is a required field": "Country is a required field",
      "birthday is a required field": "Birth date is a required field",
      'Documento alterado com sucesso': 'Document updated successfully',
      "Salvar": "Save",
      "OK": "Ok",
      "PENDING": "Pending",
      "Você fez login sem sua assinatura digital e não poderá realizar algumas ações no portal.": "You've logged in without your digital signature and won't be able to do some actions on the plataform.",
      'portuguese': "Portuguese",
      'espanish': "Espanish",
      'english': "English",
      'portuguese (Portugal)': "Portuguese (Portugal)",
      'Laudos': 'Reports',
      'Fila': 'Queue',
      'Arquivo': 'Files',
      'Permissões': 'Permissions',
      'Logout': 'Logout',
      'female': "Female",
      'male': 'Male',
      'transgender': 'Transgender',
      'transfemale': 'Female transgender',
      'transmale': 'Male transgender',
      "Status da análise": "Analysis status",
      "Sexo": "Sex",
      "Email": "Email",
      "Celular": "Cel",
      "Endereço": "Address",
      "Liberar": "Release",
      "Reservar": "Reserve",
      "Confirmar": "Confirm",
      "Documento confirmado com sucesso": "Document confirmed successfully",
      "Dados Cadastrais": "Registration data",
      "Status": "Status",
      "Digite aqui": "Type here",
      "Cpf": "Cpf",
      "Passaporte": "Passaport",
      "Certidão de nascimento": "Birth certificate",
      "CNS": "CNS",
      "Exame confirmado, gerando laudo!": "Exam confirmed, generating report!",
      "Exame": "Exam",
      "Recoletar": "Recollect",
      "Laudo gerado": "Report generated",
      "Documento validado": "Validated document",
      "Gerando laudo": "Generating report",
      "Erro ao gerar o laudo": "Error generating report",
      "Erro ao validar documento": "Error validating document",
      "Pendente": "Pending",
      "Histórico do paciente": "Patient history",
      "Aguardando confirmação": "Waiting confirmation",
      "Histórico do especialista": "Specialist history",
      "Observações": "Observations",
      "Adicionar observações do laboratório no laudo": "Add laboratory notes to the report",
      "Salvar observações": "Save Observations",
      "Resultados": "Results",
      "Resultado": "Result",
      "OD": "OD",
      "Zerar": "Reset",
      "Positivar": "Positive",
      "Dados da cápsula": "Capsule data",
      "Cápsula": "Capsule",
      "Lote interno": "Internal lot",
      "Lote externo": "External lot",
      "Leitor": "Scanner",
      "Gráfico de análise": "Analysis chart",
      "Aplicar": "Apply",
      "Sem questionário": "No Quiz",
      "Questionário": "Questionnaire",
      "Clique para visualizar a foto do exame": "Click to view the exam picture",
      "Nome": "Name",
      "Usuário": "User",
      "Validar": "Validate",
      "Permissão atualizada": "Permission updated",
      "Sem Permissão": "No permission",
      "Cancelar": "Cancel",
      "Pesquisar por Nome": "Search by name",
      "Insira sua Assinatura Digital": "Enter your digital signature",
      "Prioridade Ajustada": "Adjusted priority",
      "Prioridade": "Priority",
      "Filtros": "Filters",
      "Ações": "Actions",
      "Filtro Cadastrado": "Registered filter",
      "Documento não encontrado": "Document not found",
      "Documento encontrado": "Document found",
      "Marcar interferente na análise de": "Mark interfering in the analysis of {{examType}}",
      "LATERAL_FLOW": "Lateral Flow",
      "UV": "UV",
      "COLORIMETRY": "Colorimetry",
      "MICROSCOPY": "Microscopy",
      "MOLECULAR": "Molecular",
      'Próximo Documento': 'Next Document',
      'Próximo Exame': 'Next Exam',
      "Confirmar/Próximo": "Confirm/Next",
      "Não foi possível confirmar o documento": "Unable to confirm document",
      "Documento invalidado com sucesso": "Document successfully invalidated",
      "Erro ao invalidar o documento": "Error invalidating document",
      "Marcar interferente na análise de documento": "Mark interfering in document analysis",
      "Invalidar": "Invalidate",
      "ILLEGIBLE_PHOTO": "Illegible photo",
      "PHOTO_WITHOUT_DOCUMENT": "Photo without document",
      "DOCUMENT_WITH_PHOTO_IN_REVERSE": "Document with reversed photo",
      "DOCUMENT_WITHOUT_PHOTO": "Document without photo",
      "REGISTRATION_DIVERGENCE": "Registration divergence",
      "CHANGE_PHOTO": "No photo",
      "Erro ao atualizar permissão": 'Error al permitir el permiso',
      "Alterado da retificação": "Modificación de rectificación",
      "Error ao Retificar": "Error al rectificar",
      "Reficar": "Refica",
      "Exame deletado com sucesso": "Exam deleted successfully",
      "Erro ao deletar o exame": "Error deleting exam",
      "Tem certeza que quer remover exame da fila?": "Are you sure you want to delete the exam from queue?",
      "Deletar": "Delete",
      "Novo exame na fila": "New exam on queue",
      "Selecionar categoria": "Select Category",
      "Capsula": "Capsule",
      "Documento": "Document",
      "Codigo da Capsula": "Capsule Code",
      "Codigo do Documento": "Document Code",
      "Data inicial": "Initial date",
      "Data final": "Final date",
      "Data final deve ser maior que a data inicial": "End date must be greater than start date",
      "Exames finalizados": "Exams Completed",
      "Pesquisar por datas": "Search by dates",
      "Exames invalidos": "Invalid exams",
      "Minhas validaçoes": "My Validations",
      "Pesquisar": "Search",
      "Nenhum resultado encontrado": "No results found",
      "Nenhuma data encontrada": "No date found",
      "Horario": "Time",
      "Horas": "Hours",
      "Abrir PDF": "Open PDF",
      "Paciente": "Patient",
      "utcValue": "en-US",
      "Esse exame será retificado, deseja continuar?": "This exam will be rectified, do you want to continue?",
      "Amostra inicial": "Initial sample",
      "Amostra final": "Final sample",
      "Buffer inicial": "Initial buffer",
      "Buffer final": "Final Buffer",
      "Dados": "Data",
      "Sample": "Sanple",
      "Buffer": "Buffer",
      "Posição do buffer": "Buffer position",
      "Posição da amostra": "Sample position",
      "Delta": "Delta",
      'LYMPHOCYTES': 'Lymphocytes',
      'EOSINOPHILS': 'Eosinophils e Basophils',
      'MONOCYTES': 'Monocytes',
      'NEUTROPHILS': 'Neutrophils',
      'UNKNOWN': 'Unknown',
      'ERYTHROCYTES': 'Red cells',
      'THROMBOCYTES': 'Platelets',
      'Expira em': 'Expires in',
      'dias': 'days',
      'Permissão expirada': 'Permission expired',
      'Expira hoje': 'Expires today',
      'Local sem dispensa de foto': 'Photo-free location',
      'Local com dispensa de foto': 'Location with photo booth',
      'Nome encontrado': 'Found name',
      'Aniversário encontrado': 'Found birthday',
      "Deseja realmente deletar este filtro?": "Do you really want to delete this filter?",
      "Novo filtro": "New filter",
      "Nome do filtro": "Filter's name",
      "Busque CNPJ": "Search workplace",
      "C4i0_ERROR": "C4i0 error, please contact the support",
      "Sim": "Yes",
      "Não": "No",
      "Deseja confirmar esse exame?": "Do you want to confirm this exam?",
      "Curva ultilizada": "Used curve",
      "Curva": "Curve",
      "Valor de corrente": "Current value",
      "Result": "Result",
      "Tipo de amostra": "Sample type",
      "ID da amostra": "Sample ID",
      "Cuidado!": "Careful!",
      "Esse exame possui valores que exigem atenção!": "This exam has values ​​that faced attention!",
      "Por quem": "Who",
      "Motivo": "Motive",
      "Laudo invalido": "Invalid report",
      "Gráfico de transmitância": "Transmittance Graph",
      "SAVE": "Save successfully!",
      "Technical manager token is expired": "Expired token",
      "DEFAULT_DOCUMENT_ERROR_MESSAGE": "Unable to confirm document",
      "DEFAULT_REPORT_ERROR_MESSAGE": "Failed to generate report",
      "Observações interna": "Internal observations",
      "Observações não encontradas": "Observation not found",
      "Resultados Separados": "Split Results",
      "Interferentes": "Interference",
      "Por": "By",
      "Atualizado em": "Updated on",
      "Motivo de inválido": "Invalid reason",
      "PRIMEIRA FOTO": "FIRST PHOTO",
      "SEGUNDA FOTO": "SECOND PHOTO",
      "Fator de correção": "Correction factor",
      "Interpolações": "Interpolations",
      "Tipo da curva": "Type of curve",
      "Valor de concentração": "Concentration value",
      "Ponto aplicado": "Applied point",
      "Quantidade de medidas": "Quantity of measurements",
      "Brilho": "Brightness",
      "Contraste": "Contrast",
      "Corrente (µA)": "Current (µA)",
      "Valores espectrômetro": "Spectrometer values",
      "Valores espectrômetro branco": "White spectrometer values",
      "Tempo de processamento": "Processing time",
      "Transmitância": "Transmittance",
      "Canal": "Channel",
      "Branco": "White",
      "MEDIDAS": "MEASURES",
      "EXAME DE AMPEROMETRIA CINÉTICA": "KINETIC AMPEROMETRY EXAM",
      "Indeterminado": "Indeterminate",
      "Controle": "Control",
      "Pontos marcados": "Points marked",
      "Zerar todos": "Reset all",
      "Comprimento de onda": "Wavelength",
      "Você tem um ponto fora da curva": "You have a point outside the curve",
      "Ponto inicial": "Initial point",
      "Ponto final": "Final point",
      "Reprocessar": "Reprocess",
      "training": "TRAINING",
      "Error searching valid limits of analyte(s) ": "Error searching valid limits of analyte(s): ",
      "An error occurred sending report": "An error occurred sending report",
      "Tempo": "TIME (S)",
      "EXAM_RECOLLECTED": "This exam has been recollected!",
      "sampleType": "Sample Type",
      "expectedResult": "Expected Result",
      "analyte": "Analyte",
      "ODControl": "OD Control",
      "real_sample": "Real sample",
      "control": "Control",
      "notInformed": "Not informed"
    },
  },
};

export default translation;
