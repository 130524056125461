import { ResourceKey } from 'i18next'

const translation: ResourceKey = {
  es: {
    translation: {
      hello: "Hello!",
      "username is a required field": "El nombre de usuario es un campo obligatorio",
      "password is a required field": "La contraseña es un campo requerido",
      "Login": "Login",
      "Senha": "Contraseña",
      "Assinatura Digital": "Firma digital",
      "Entrar": "Ingresar",
      "Internal Server Error": "Error interno del servidor, inténtalo de nuevo más tarde.",
      "invalid_grant": "Credenciales incorrectas, nombre de usuario y/o contraseña incorrectos, inténtelo de nuevo.",
      "unauthorized": "No autorizado",
      "name": "Nome",
      "newDocument": "Documento",
      "documentType": "Tipo do Documento",
      "country": "País",
      "birthday": "Data de nascimento",
      "socialName": "Nombre Social",
      "name is a required field": "Nome é um campo obrigatório",
      "newDocument is a required field": "Documento é um campo obrigatório",
      "documentType is a required field": "Tipo do Documento é um campo obrigatório",
      "country is a required field": "País é um campo obrigatório",
      "birthday is a required field": "Data de nascimento é um campo obrigatório",
      'Documento alterado com sucesso': 'Documento alterado com sucesso',
      "Salvar": "Salvar",
      "OK": "Ok",
      "PENDING": "Pendente",
      "Aguardando confirmação": "Aguardando confirmação",
      "Você fez login sem sua assinatura digital e não poderá realizar algumas ações no portal.": "Você fez login sem sua assinatura digital e não poderá realizar algumas ações no portal.",
      'portuguese': "Portugués",
      'espanish': "Español",
      'english': "Inglés",
      'portuguese (Portugal)': "Portugués (Portugal)",
      "Laudos": "Informes",
      "Fila": "Fila",
      "Arquivo": "Archivo",
      "Permissões": "Permisos",
      "Logout": "Salir",
      "female": "Femenino",
      "male": "Masculino",
      "transgender": "Transgénero",
      "transfemale": "Transgénero femenino",
      "transmale": "Transgénero masculino",
      "Status da análise": "Estado del análisis",
      "Sexo": "Sexo",
      "Email": "Correo electrónico",
      "Celular": "Teléfono celular",
      "Endereço": "Dirección",
      "Liberar": "Liberar",
      "Reservar": "Reservar",
      "Confirmar": "Confirmar",
      "Documento confirmado com sucesso": "Documento confirmado exitosamente",
      "Dados Cadastrais": "Datos de registro",
      "Status": "Estado",
      "Digite aqui": "Escribe aquí",
      "Cpf": "CPF",
      "Passaporte": "Pasaporte",
      "Certidão de nascimento": "Certificado de nacimiento",
      "CNS": "CNS",
      "Exame confirmado, gerando laudo!": "Examen confirmado, generando informe!",
      "Exame": "Examen",
      "Recoletar": "Recolectar",
      "Laudo gerado": "Informe generado",
      "Documento validado": "Documento validado",
      "Gerando laudo": "Generando informe",
      "Erro ao gerar o laudo": "Error al generar el informe",
      "Erro ao validar documento": "Error al validar el documento",
      "Pendente": "Pendiente",
      "Histórico do paciente": "Historial del paciente",
      "Histórico do especialista": "Historial del especialista",
      "Observações": "Observaciones",
      "Adicionar observações do laboratório no laudo": "Agregar observaciones del laboratorio en el informe",
      "Salvar observações": "Guardar Observaciones",
      "Resultados": "Resultados",
      "Resultado": "Resultado",
      "OD": "OD",
      "Zerar": "Reiniciar",
      "Positivar": "Positivar",
      "Dados da cápsula": "Datos de la cápsula",
      "Cápsula": "Cápsula",
      "Lote interno": "Lote interno",
      "Lote externo": "Lote externo",
      "Leitor": "Lector",
      "Gráfico de análise": "Gráfico de análisis",
      "Aplicar": "Aplicar",
      "Sem questionário": "Sin Cuestionario",
      "Questionário": "Cuestionario",
      "Clique para visualizar a foto do exame": "Haga clic para ver la imagen del examen",
      "Nome": "Nombre",
      "Usuário": "Usuario",
      "Validar": "Validar",
      "Permissão atualizada": "Permiso actualizado",
      "Sem Permissão": "Sin permiso",
      "Cancelar": "Cancelar",
      "Pesquisar por Nome": "Buscar por nombre",
      "Insira sua Assinatura Digital": "Ingrese su firma digital",
      "Prioridade Ajustada": "Prioridad ajustada",
      "Prioridade": "Prioridad",
      "Filtros": "Filtros",
      "Ações": "Acciones",
      "Filtro Cadastrado": "Filtro registrado",
      "Documento não encontrado": "Documento no encontrado",
      "Documento encontrado": "Document found",
      "Marcar interferente na análise de": "Mark interfiere en el análisis de {{examType}}",
      "LATERAL_FLOW": "Lateral Flow",
      "UV": "UV",
      "COLORIMETRY": "Colorimetría",
      "MICROSCOPY": "Microscopía",
      "MOLECULAR": "Molecular",
      'Próximo Documento': 'Próximo documento',
      'Próximo Exame': 'Próximo examen',
      "Confirmar/Próximo": "Confirmar/Próximo",
      "Não foi possível confirmar o documento": "No se puede confirmar el documento",
      "Documento invalidado com sucesso": "Documento invalidado con éxito",
      "Erro ao invalidar o documento": "Error al invalidar el documento",
      "Marcar interferente na análise de documento": "Marca que interfiere en el análisis de documentos",
      "Invalidar": "Invalidar",
      "ILLEGIBLE_PHOTO": "Foto ilegible",
      "PHOTO_WITHOUT_DOCUMENT": "Foto sin documento",
      "DOCUMENT_WITH_PHOTO_IN_REVERSE": "Documento con foto invertida",
      "DOCUMENT_WITHOUT_PHOTO": "Documento sin foto",
      "REGISTRATION_DIVERGENCE": "Divergencia de registro",
      "CHANGE_PHOTO": "Sin fotografía",
      "ERROR": "Erro",
      "Erro ao atualizar permissão": 'Error allowing permission',
      "Alterado da retificação": "Modification of rectification",
      "Error ao Retificar": "Error while rectifying",
      "Reficar": "Reficate",
      "Exame deletado com sucesso": "Examen eliminado con éxito",
      "Erro ao deletar o exame": "Error al eliminar el examen",
      "Tem certeza que quer remover exame da fila?": "¿Estás segura de que quieres eliminar el examen de la fila?",
      "Deletar": "Borrar",
      "Novo exame na fila": "Nuevo examen en fila",
      "Selecionar categoria": "Selecciona una categoría",
      "Capsula": "Cápsula",
      "Documento": "Documento",
      "Codigo da Capsula": "Código de la cápsula",
      "Codigo do Documento": "Código de documento",
      "Data inicial": "La fecha de inicio",
      "Data final": "Fecha final",
      "Data final deve ser maior que a data inicial": "La fecha de finalización debe ser mayor que la fecha de inicio",
      "Exames finalizados": "Exámenes completados",
      "Pesquisar por datas": "Buscar por fechas",
      "Exames invalidos": "Exámenes inválidos",
      "Minhas validaçoes": "Mis Validaciones",
      "Pesquisar": "Búsqueda",
      "Nenhum resultado encontrado": "Ningún resultado encontrado",
      "Nenhuma data encontrada": "No se encontró fecha",
      "Horario": "Tiempo",
      "Horas": "Horas",
      "Abrir PDF": "Abrir PDF",
      "Paciente": "Paciente",
      "utcValue": "es-ES",
      "Esse exame será retificado, deseja continuar?": "Este examen será rectificado, ¿quieres continuar?",
      "Amostra inicial": "Muestra inicial",
      "Amostra final": "Muestra final",
      "Buffer inicial": "Búfer inicial",
      "Buffer final": "Búfer final",
      "Dados": "Datos",
      "Sample": "Amostra",
      "Buffer": "Buffer",
      "Posição do buffer": "Posición del buffer",
      "Posição da amostra": "posición de la muestra",
      "Delta": "Delta",
      'LYMPHOCYTES': 'Linfócitos',
      'EOSINOPHILS': 'Eosinófilos e Basófilos',
      'MONOCYTES': 'Monócitos',
      'NEUTROPHILS': 'Neutrófilos',
      'UNKNOWN': 'Desconhecidas',
      'ERYTHROCYTES': 'Hemácias',
      'THROMBOCYTES': 'Plaquetas',
      'Expira em': 'Caduca en',
      'dias': 'días',
      'Permissão expirada': 'Permiso caducado',
      'Expira hoje': 'Vence hoy',
      'Local sem dispensa de foto': 'Ubicación sin fotos',
      'Local com dispensa de foto': 'Ubicación con fotomatón',
      'Nome encontrado': 'Nombre encontrado',
      'Aniversário encontrado': 'Fecha de nacimiento encontrado',
      "Deseja realmente deletar este filtro?": "¿Realmente desea eliminar este filtro?",
      "Novo filtro": "Filtro nuevo",
      "Nome do filtro": "Nombre del filtro",
      "Busque CNPJ": "Buscar CNPJ",
      "C4i0_ERROR": "Erro no C4i0, póngase en contacto con soporte",
      "Sim": "Sí",
      "Não": "No",
      "Deseja confirmar esse exame?": "¿Quieres confirmar este examen?",
      "Curva ultilizada": "Curva usada",
      "Curva": "Curva",
      "Result": "Resultado",
      "Tipo de amostra": "Tipo de ejemplo",
      "ID da amostra": "Ejemplo de id",
      "Cuidado!": "Cuidadoso!",
      "Esse exame possui valores que exigem atenção!": "¡Este examen tiene valores que enfrentaron la atención!",
      "Por quem": "Por quién",
      "Motivo": "Razón",
      "Laudo invalido": "Informe inválido",
      "Gráfico de transmitância": "Gráfico de transmitância",
      "Technical manager token is expired": "Suscripción caducada",
      "DEFAULT_DOCUMENT_ERROR_MESSAGE": "No se puede confirmar el documento",
      "DEFAULT_REPORT_ERROR_MESSAGE": "No se pudo generar el informe",
      "Observações interna": "Observaciones internas",
      "Observações não encontradas": "Observaciones no encontradas ",
      "SAVE": "Guardado con éxito!",
      "save": "Guardado con éxito!",
      "Resultados Separados": "Resultados Separados",
      "Interferentes": "Interferencia",
      "Por": "Por",
      "Atualizado em": "Actualizado el",
      "Motivo de inválido": "Motivo no válido",
      "PRIMEIRA FOTO": "PRIMERA FOTO",
      "SEGUNDA FOTO": "SEGUNDA FOTO",
      "Fator de correção": "Factor de corrección",
      "Interpolações": "Interpolaciones",
      "Tipo da curva": "Tipo de curva",
      "Valor de concentração": "Valor de concentración",
      "Ponto aplicado": "Punto aplicado",
      "Quantidade de medidas": "Cantidad de medidas",
      "Brilho": "Brillo",
      "Valor de corrente": "Valor de energía",
      "Contraste": "Contraste",
      "Corrente (µA)": "Energía (µA)",
      "Valores espectrômetro": "Valores del espectrómetro",
      "Valores espectrômetro branco": "Valores del espectrómetro blanco",
      "Tempo de processamento": "Tiempo de procesamiento",
      "Transmitância": "Transmitancia",
      "Canal": "Canal",
      "Branco": "Blanco",
      "MEDIDAS": "MEDIDAS",
      "EXAME DE AMPEROMETRIA CINÉTICA": "EXAMEN DE AMPEROMETRÍA CINÉTICA",
      "Indeterminado": "Indeterminado",
      "Controle": "Control",
      "Pontos marcados": "Puntos marcados",
      "Zerar todos": "Resetear todo",
      "Comprimento de onda": "Longitud de onda",
      "Você tem um ponto fora da curva": "Tienes un punto fuera de la curva",
      "Ponto inicial": "Punto inicial",
      "Ponto final": "Punto final",
      "Reprocessar": "Reprocesar",
      "training": "CAPACITACIÓN",
      "Error searching valid limits of analyte(s) ": "No se puede encontrar un límite para los analitos: ",
      "An error occurred sending report": "Error al enviar el resultado",
      "Tempo": "TIEMPO (S)",
      "EXAM_RECOLLECTED": "Este examen fue recolectado!",
      "notInformed": "No informado"
    },
  },
};

export default translation;
